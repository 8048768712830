import { DefaultApolloClient } from '@vue/apollo-composable';
import { AuthTokenResponse } from '~~/iris/src/api';
import { NestJSApi } from '~~/iris/src/nestjs'
import { getAuthHeaders } from '~~/utils/authorisation';

export function useApolloClientInject({
  useWithoutLogin = false
} : {
  /** disable requirement for auth token for apollo client */
  useWithoutLogin?: boolean
} = {}) {
    // import Spinner from 'vue-spinner/src/RiseLoader.vue'
    
    
    const nuxtApp = useNuxtApp()
    const config = useRuntimeConfig()
    
      const nestApi = new NestJSApi({
              prefix: process.client ? (config.public.irisNestUrl || '/iris-estia') : config.irisNestUrl,
              api: {
                  getAuthToken() {
                      if (useWithoutLogin) {
                        return Promise.resolve(null)
                      }
                      return getAuthHeaders(nuxtApp.$auth).then(({ Authorization }): AuthTokenResponse => {
                        if (Authorization) {
                          const [ tokenType, accessToken ] = Authorization.split(' ', 2)
                          return {
                            accessToken,
                            tokenType: tokenType as 'Bearer',
                            expiresIn: 30,
                            message: 'ok',
                            status: true
                          }
                        }
                        throw new Error('no logged in no token')
                      })
                  },
              }
          })
          provide(DefaultApolloClient, nestApi.apolloClient())
    
    return {
      client: DefaultApolloClient
    }
    
}