/* eslint-disable no-console */

import { AxiosInstance } from 'axios'
import { autoAuthAxios } from './feathersjs/axios'
import Api from './api'
import { Iris2Api, IAccount, IAccountResponse, Presentation, IIrisAccount } from './nestjs-interfaces'
import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client/core/index.js'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws/index.js'
import { setContext } from '@apollo/client/link/context/index.js'
import { getMainDefinition } from '@apollo/client/utilities/index.js'
import { ExistingAccount, IAccountCreation, IAccountInitialPayment, IAccountInitialPaymentResponse } from './nestjs-interfaces-recurly'

export class NestJSApi implements Iris2Api {
  private readonly axios: AxiosInstance
  private readonly prefix: string
  private _apolloClient?: ApolloClient<any>
  private readonly api: Pick<Api, 'getAuthToken'>

  constructor ({
    api,
    prefix,
    axios
  }: {
    api: Pick<Api, 'getAuthToken'>,
    prefix: string,
    axios?: AxiosInstance
  }) {
    this.api = api
    this.axios = axios ?? autoAuthAxios(api)
    this.prefix = prefix
  }

  createRecurlyAccount (account: IAccountCreation) {
    return this.axios.request<Pick<ExistingAccount, 'accountId'>>({
      method: 'POST',
      url: `${this.prefix}/api/recurly/create-account`,
      data: account
    }).then(r => r.data)
  }

  processInitialPayment (initialPayment: IAccountInitialPayment) {
    return this.axios.request<IAccountInitialPaymentResponse>({
      method: 'POST',
      url: `${this.prefix}/api/recurly/initial-payment`,
      data: initialPayment
    }).then(r => r.data)
  }

  apolloClient (): ApolloClient<any> {
    if (!this._apolloClient) {
      const url = new URL(`${this.prefix}/api/graphql`, process.client ? window.location.toString() : undefined)
      url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'
      const httpLink = createHttpLink({
        uri: `${this.prefix}/api/graphql`,
        fetch: (url, options) => {
          return fetch(url, {
            ...options,
            headers: Array.isArray(options.headers) ? options.headers : { ...options.headers }
          })
        }
      })
      const authLink = setContext((_, { headers }) => {
        return this.api.getAuthToken().then(token => {
          if (token) {
            return {
              headers: {
                ...headers,
                authorization: `${token.tokenType} ${token.accessToken}`
              }
            }
          }
          return {
            headers
          }
        })
      })
      let apolloLink = authLink.concat(httpLink)
      if (process.client) {
        // websocket not implemented in nodejs strip out
        const wsLink = new WebSocketLink(
          new SubscriptionClient(url.toString(), {
            //
            reconnect: true,
            lazy: true,
            connectionParams: () => this.api.getAuthToken().then(token => {
              if (token) {
                return {
                  headers: {
                    authorization: `${token.tokenType} ${token.accessToken}`
                  }
                }
              }
            })
          })
        )
        apolloLink = split(({ query }) => {
          const definition = getMainDefinition(query)
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        }, wsLink, apolloLink)
      }
      const cache = new InMemoryCache()
      this._apolloClient = new ApolloClient({
        cache,
        link: apolloLink,
        connectToDevTools: process.env.NODE_ENV === 'development'
      })
    }
    return this._apolloClient
  }

  setupRecurlyDirectDebit (account: IAccount): Promise<IAccountResponse> {
    return this.axios.request<IAccountResponse>({
      method: 'POST',
      url: `${this.prefix}/api/recurly/create-subscription`,
      data: account
    }).then(r => r.data)
  }

  todaysPresentations (): Promise<Presentation[]> {
    return this.axios.request<Presentation[]>({
      method: 'GET',
      url: `${this.prefix}/api/iris/presentation/today`
    }).then(r => r.data)
  }

  convertPresentation (account: IIrisAccount, presentationId?: string): Promise<{}> {
    return this.axios.request<{}>({
      method: 'POST',
      url: `${this.prefix}/api/iris/presentation${presentationId ? `/${presentationId}/convert` : ''}`,
      data: account
    }).then(r => r.data)
  }
}
